import '../components/News.css'
import Footer from './Footer'
import NewsItem from '../components/NewsItem'
import '../components/News.css'
import '../components/CompetitionNewsItem.css';
import {SocialIcon} from "react-social-icons";
import CompetitionItem from "../components/CompetitionNewsItem";
import {useState} from "react";


const News = (props) => {

    const urlClubMatches = 'https://vblcb.wisseq.eu/VBLCB_WebService/data/OrgMatchesByGuid?issguid=BVBL1032';
    const dateNow = new Date();
    dateNow.setHours(0, 0, 0)
    const until = new Date(dateNow.setDate(dateNow.getDate() + (7 - dateNow.getDay())));
    const searchGamesForClub = async () => {

        const responseClubMatches = await fetch(urlClubMatches);
        const dataClubMatches = await responseClubMatches.json();
        setGames(dataClubMatches);
    }


    const [games, setGames] = useState(() => {
       searchGamesForClub()

    });

    const dateSortString = (aDateString, aTimeString) => {
        var year = aDateString.substring(6, 10);
        var month = aDateString.substring(3, 5);
        var day = aDateString.substring(0, 2);
        return year + month + day + aTimeString;
    }

    const dateStringToDate = (aString) => {
        var year = aString.substring(6, 10);
        var month = aString.substring(3, 5);
        var day = aString.substring(0, 2);
        return new Date(month + '-' + day + '-' + year);
    }


    const checkDate = (dateString) => {
        const date = dateStringToDate(dateString);
        var from = new Date();
        from.setHours(0, 0, 0)
        from.setDate(from.getDate() - 1)


        return date >= from  && date <= until
    }


    const filterGames = () => {
        let arr = [];
        games?.forEach((game) => {
            if ( checkDate(game.datumString) ) {
                arr.push(game)
            }
        });
        arr = arr.sort((a, b) => dateSortString(a.datumString, a.beginTijd).localeCompare(dateSortString(b.datumString, b.beginTijd)))

        return arr;
    }


    function importAll(r) {
        return r.keys().map(r);
    }

    const images = importAll(require.context('../assets/newsItems', false, /\.(png|jpe?g|svg)$/));

    return (
        <>
            <div className="newsPage">
                <h1>News</h1>
                <br/>
                <ul className="newsItemsList">
                    {
                        images.map((anImage, index) => (
                            <li className="newsItemsListItems" key={index}>
                                <NewsItem path={anImage}></NewsItem>
                            </li>
                        ))
                    }
                    {
                        filterGames().map((game) => (<CompetitionItem game={game}/>))
                    }
                </ul>
                <div className="socialMedia">
                    <h2>follow us on</h2>
                    <div className="socialMediaIcons">
                        <SocialIcon className="icons" url="https://www.instagram.com/blackdevilsvorst/"
                                    bgColor="#cd1c21"
                                    fgColor="white"/>
                        <SocialIcon className="icons" url="https://www.facebook.com/BlackDevilsVorst"
                                    bgColor="#cd1c21"/>
                    </div>
                </div>
            </div>
            <Footer/>

        </>
    );
}
export default News

